// Vendor Imports
import _ from 'lodash';

// Project Imports
import { getCurrentSeriesIndex, getSelectedVisualizationType } from './vifAuthoring';
import { dataProviders } from 'common/visualizations';
import {
  getDisplayableColumns as baseGetDisplayableColumns,
  getFilterableColumns as baseGetFilterableColumns,
  isHiddenColumn,
  isSubcolumn
} from 'common/visualizations/dataProviders/MetadataProvider';
import getDefaultDomain from 'common/visualizations/helpers/getDefaultDomain';
import { createSelector } from 'reselect';
import { isRegionComputedColumn, isNotRegionComputedColumn } from 'common/column/utils';

// Constants
import {
  CALENDAR_DATE_COLUMN_TYPE,
  CHECKBOX_COLUMN_TYPE,
  DIMENSION_TYPES,
  GEO_LOCATION_COLUMN_TYPES,
  NUMERIC_COLUMN_TYPES,
  VISUALIZATION_TYPES
} from '../constants';
import { FeatureFlags } from 'common/feature_flags';

const getLoading = (state) => state.isLoading;
const getUpdating = (state) => state.isUpdating;
const getDomain = (state) => {
  return state.domain || getDefaultDomain();
};
const getDatasetUid = (state) => state.datasetUid;
const getDatasetMetadata = (state) => state.data;
const getCuratedRegions = (state) => state.curatedRegions;

const getComputedRegionColumns = (state) => state.computedRegionColumns;
const hasColumnStats = (state) => state.hasColumnStats;
const getError = (state) => state.error;

export const isLoading = createSelector(getLoading, _.identity);
export const isUpdating = createSelector(getUpdating, _.identity);
export const hasData = createSelector(getDatasetMetadata, (datasetMetadata) => !_.isNull(datasetMetadata));
export const hasError = createSelector(getError, (error) => !_.isNull(error));

export const getCurrentMetadata = (metadataCollection, vifAuthoring) => {
  let currentSeriesIndex = 0;
  if (getSelectedVisualizationType(vifAuthoring) === 'map') {
    currentSeriesIndex = getCurrentSeriesIndex(vifAuthoring);
  }

  return metadataCollection[currentSeriesIndex];
};

export const getCurrentSourceName = (metadataCollection, currentSeriesIndex = 0) => {
  return _.get(metadataCollection[currentSeriesIndex], 'data.name');
};

export const getCurrentDomainName = (layer) => {
  return _.get(layer.dataSource, 'domain') || getDefaultDomain();
};

export const getDatasetName = createSelector(getDatasetMetadata, (datasetMetadata) =>
  _.get(datasetMetadata, 'name')
);

export const getDisplayableColumns = createSelector(
  getDomain,
  getDatasetUid,
  getDatasetMetadata,
  (domain, datasetUid, datasetMetadata) => {
    if (datasetMetadata) {
      return baseGetDisplayableColumns(datasetMetadata);
    } else {
      return []; // No data yet.
    }
  }
);

export const getCheckboxColumns = createSelector(
  getDisplayableColumns,
  getDatasetMetadata,
  (displayableColumns, datasetMetadata) => {
    return _.chain(displayableColumns)
      .filter(isCheckboxColumn)
      .map(toDatasetMetadata(datasetMetadata))
      .sortBy('name')
      .value();
  }
);

export const getNumericalColumns = createSelector(
  getDisplayableColumns,
  getDatasetMetadata,
  (displayableColumns, datasetMetadata) => {
    return _.chain(displayableColumns)
      .filter(isNumericColumn)
      .map(toDatasetMetadata(datasetMetadata))
      .sortBy('name')
      .value();
  }
);

export const getFilterableColumns = createSelector(getDisplayableColumns, (columns) => {
  return baseGetFilterableColumns({ columns });
});

export const getDatasetLink = createSelector(
  getDomain,
  getDatasetUid,
  (domain, datasetUid) => `https://${domain}/d/${datasetUid}`
);

export const getValidDimensions = createSelector(
  getDomain,
  getDatasetUid,
  getDatasetMetadata,
  (domain, datasetUid, datasetMetadata) => {
    return _.chain(datasetMetadata)
      .get('columns')
      .filter(isNotSystemColumn)
      .filter(isNotRegionComputedColumn)
      .filter((column) => !isSubcolumn(column.fieldName, datasetMetadata))
      .filter((column) => !isHiddenColumn(column.flags))
      .map(toDatasetMetadata(datasetMetadata))
      .sortBy('name')
      .value();
  }
);

export const getRecommendedDimensions = (state, type) => {
  const dimensions = getValidDimensions(state);
  const visualizationType = _.find(VISUALIZATION_TYPES, (visualization) => visualization.type === type);

  return _.filter(dimensions, (dimension) => {
    return (
      visualizationType && _.includes(visualizationType.preferredDimensionTypes, dimension.renderTypeName)
    );
  });
};

export const isDimensionTypeCalendarDate = (state, column) => {
  const dimensionType = getDimensionType(state, column);
  return !_.isNil(dimensionType) && dimensionType.type === CALENDAR_DATE_COLUMN_TYPE;
};

export const isDimensionTypeCheckbox = (state, column) => {
  const dimensionType = getDimensionType(state, column);
  return !_.isNil(dimensionType) && dimensionType.type === CHECKBOX_COLUMN_TYPE;
};

export const isDimensionTypeNumeric = (state, column) => {
  const dimensionType = getDimensionType(state, column);
  return !_.isNil(dimensionType) && _.includes(NUMERIC_COLUMN_TYPES, dimensionType.type);
};

export const isPointMapColumn = (state, column) => {
  const dimensionType = getDimensionType(state, column);

  return !_.isUndefined(dimensionType) && _.includes(['location', 'point', 'multipoint'], dimensionType.type);
};

export const isLineMapColumn = (state, column) => {
  const dimensionType = getDimensionType(state, column);

  return !_.isUndefined(dimensionType) && _.includes(['line', 'multiline'], dimensionType.type);
};

export const isBoundaryMapColumn = (state, column) => {
  const dimensionType = getDimensionType(state, column);

  return !_.isUndefined(dimensionType) && _.includes(['polygon', 'multipolygon'], dimensionType.type);
};

export const getMapType = (state, column) => {
  const dimensionType = getDimensionType(state, column);
  let mapType = null;

  if (_.isUndefined(dimensionType)) {
    return mapType;
  }

  switch (dimensionType.type) {
    case 'location':
    case 'point':
    case 'multipoint':
      mapType = 'pointMap';
      break;

    case 'line':
    case 'multiline':
      mapType = 'lineMap';
      break;

    case 'polygon':
    case 'multipolygon':
      mapType = 'boundaryMap';
      break;
  }

  return mapType;
};

export const getDimensionType = (state, column) => {
  const dimension = _.find(getValidDimensions(state), (dimension) => {
    return column && column.columnName === dimension.fieldName;
  });

  return _.find(DIMENSION_TYPES, (dimensionType) => {
    return dimension && dimension.renderTypeName === dimensionType.type;
  });
};

export const getRecommendedVisualizationTypes = (state, column) => {
  const dimensionType = getDimensionType(state, column);

  return _.filter(VISUALIZATION_TYPES, (visualization) => {
    return dimensionType && _.includes(dimensionType.preferredVisualizationTypes, visualization.type);
  });
};

export const getValidFlyoutMeasures = createSelector(getDatasetMetadata, (datasetMetadata) => {
  return _.chain(datasetMetadata.columns)
    .filter(_.overEvery([isNotSystemColumn, isNotRegionComputedColumn]))
    .reject((column) => isHiddenColumn(column.flags))
    .map(toDatasetMetadata(datasetMetadata))
    .sortBy('name')
    .value();
});

export const getValidMeasures = createSelector(getDatasetMetadata, (datasetMetadata) => {
  return _.chain(datasetMetadata.columns)
    .filter(_.overEvery([isNumericColumn, isNotSystemColumn, isNotRegionComputedColumn]))
    .reject((column) => isHiddenColumn(column.flags))
    .map(toDatasetMetadata(datasetMetadata))
    .sortBy('name')
    .value();
});

export const getValidComputedColumns = createSelector(
  getComputedRegionColumns,
  (data) => {
    if (_.isNil(data)) {
      return [];
    }

    return _.chain(data)
      .map(extendWithComputedColumnNameAndUid)
      .sortBy('name')
      .value();
  });

export const getValidCuratedRegions = createSelector(
  getCuratedRegions,
  getValidComputedColumns,
  (curatedRegions, computedColumns) => {
    const notInDataset = (region) => {
      return !_.some(computedColumns, { uid: region.uid });
    };

    return _.chain(curatedRegions).filter(notInDataset).sortBy('name').value();
  }
);

export const hasRegions = createSelector(
  getValidCuratedRegions,
  getValidComputedColumns,
  (curatedRegions, computedColumns) => curatedRegions.length > 0 || computedColumns.length > 0
);

export const getAnyLocationColumn = createSelector(getDisplayableColumns, (columns) =>
  _.find(columns, isGeoLocationColumn)
);

export const getNonGeoLocationColumns = createSelector(getDisplayableColumns, (columns) =>
  _.reject(columns, isGeoLocationColumn)
);

export const getFirstOccurringGeoLocationColumn = createSelector(getDisplayableColumns, (columns) =>
  _.find(columns, isGeoLocationColumn)
);

export const getSoqlDataProvider = createSelector(
  getDomain,
  getDatasetUid,
  (domain, datasetUid) => new dataProviders.SoqlDataProvider({ domain, datasetUid }, true)
);

export const isGeoLocationColumn = (column) => {
  const renderTypeName = _.get(column, 'renderTypeName');
  return _.includes(GEO_LOCATION_COLUMN_TYPES, renderTypeName);
};

const toDatasetMetadata = (metadata) => (column) => _.find(metadata.columns, { fieldName: column.fieldName });

export const isBooleanColumn = (metadata, column) => {
  const metadataColumns = _.get(metadata, 'data.columns');
  const columnDetails = _.find(metadataColumns, ['fieldName', column]);
  return _.get(columnDetails, 'renderTypeName') === CHECKBOX_COLUMN_TYPE;
};

export const hasAtleastOneBooleanColumn = (metadata, columns) => {
  const columnDetails = _.map(columns, (column) => {
    const metadataColumns = _.get(metadata, 'data.columns');
    return _.find(metadataColumns, ['fieldName', column]);
  });

  return _.some(columnDetails, ['renderTypeName', CHECKBOX_COLUMN_TYPE]);
};

/**
 * True means to disable the region map dropdown.
 * False means to show the region map dropdown.
 */
export const shouldNotShowRegionMapOption = (metadata) => {
  const isDerivedDataset = !_.get(metadata, ['data', 'flags'], []).includes('default');
  /** The write right is revoked on all data federated assets,
   * which hides the option to choose processing maps/curated
   * regions from users on target domains. */
  const hasWriteRight = _.get(metadata, ['data', 'rights'], []).includes('write');
  const computedColumns = getValidComputedColumns(metadata);
  const curatedRegions = isDerivedDataset ? [] : getValidCuratedRegions(metadata);

  return hasWriteRight ? _.isEmpty(computedColumns) && _.isEmpty(curatedRegions) : _.isEmpty(computedColumns);
};

const isNotSystemColumn = (column) => {
  return !_.startsWith(column.name, ':');
};

const extendWithComputedColumnNameAndUid = (region) => {
  return _.extend({}, region, {
    fieldName: region.fieldName,
    name: region.name,
    uid: region.computationStrategy.parameters.region.slice(1)
  });
};

const isCheckboxColumn = (column) => {
  const renderTypeName = _.get(column, 'renderTypeName');
  return renderTypeName === CHECKBOX_COLUMN_TYPE;
};

const isNumericColumn = (column) => {
  const renderTypeName = _.get(column, 'renderTypeName');
  return _.includes(NUMERIC_COLUMN_TYPES, renderTypeName);
};
