import { cloneDeep, each, get, isUndefined, map, set, size } from 'lodash';

import vifs from 'common/authoring_workflow/vifs';
import baseVifReducer from './base.js';

import * as actions from '../../actions.js';
import { forEachSeries } from 'common/authoring_workflow/helpers';
import { Vif } from 'common/visualizations/vif';
import { Hierarchy, HierarchyColumnConfig } from 'common/visualizations/vif';
import { HIERARCHY_AGGREGATION_TYPES } from 'common/authoring_workflow/constants';
import { ViewColumn } from 'common/types/viewColumn';

export default function agTable(state: any, action: any) {
  if (isUndefined(state)) {
    return vifs().agTable;
  }

  state = cloneDeep(state);

  switch (action.type) {
    case actions.RESET_STATE: {
      state = vifs().agTable;
      break;
    }

    case actions.REPLACE_VIF: {
      state = action.vif;
      break;
    }

    case actions.SET_TABLE_COLUMNS: {
      const { tableColumns } = action;
      const path = ['series', 0, 'dataSource', 'dimension', 'columns'];
      set(state, path, tableColumns);
      break;
    }

    case actions.SET_COLUMN_ORDER_CONFIGURATION: {
      const { order } = action;
      set(state, 'configuration.order', order);
      break;
    }

    case actions.SET_TABLE_VIZ_COLUMN_FORMATS: {
      const { columnFormats } = action;
      set(state, ['series', 0, 'formatting', 'columnFormat'], columnFormats);
      break;
    }

    case actions.UPDATE_TABLE_VIZ_COLUMN_FORMAT: {
      const { updatedColumnFormat, columnName } = action;
      set(state, ['series', 0, 'formatting', 'columnFormat', columnName], updatedColumnFormat);
      break;
    }

    case actions.UPDATE_TABLE_VIZ_COLUMN_STYLE: {
      const { updatedColumnFormatStyle, columnName } = action;
      set(state, ['series', 0, 'formatting', 'columnFormat', columnName, 'style'], updatedColumnFormatStyle);
      break;
    }

    case actions.UPDATE_TABLE_VIZ_COLUMN_STYLE_CONDITION: {
      const { updatedColumnFormatStyle, columnName } = action;
      set(
        state,
        ['series', 0, 'formatting', 'columnFormat', columnName, 'conditionStyle'],
        updatedColumnFormatStyle
      );
      break;
    }

    case actions.UPDATE_TABLE_VIZ_HEADER_STYLE: {
      const { updatedHeaderFormatStyle } = action;
      set(state, ['series', 0, 'formatting', 'headerFormat'], updatedHeaderFormatStyle);
      break;
    }

    case actions.UPDATE_TABLE_VIZ_HEADER_TEXT_STYLE: {
      const { updatedHeaderFormatTextStyle } = action;
      set(state, ['series', 0, 'formatting', 'headerFormat', 'fontStyle'], updatedHeaderFormatTextStyle);
      break;
    }

    case actions.SET_ACTIVE_HIERARCHY_ID: {
      const { hierarchyId } = action;
      set(state, ['series', 0, 'dataSource', 'activeHierarchyId'], hierarchyId);
      break;
    }

    case actions.NEW_TABLE_HIERARCHY:
      forEachSeries(state, (series: Vif['series'][number]) => {
        const id = action.id;
        const hierarchiesIndex = size(get(series, ['dataSource', 'hierarchies']));
        set(series, ['dataSource', 'hierarchies', hierarchiesIndex], {
          id,
          columnConfigurations: [],
          showGrandTotal: false,
          showSubTotal: false
        });

        // make the new hierarchy active
        set(series, ['dataSource', 'activeHierarchyId'], id);
      });
      break;

    case actions.SET_TABLE_HIERARCHIES: {
      const { tableHierarchies } = action;
      forEachSeries(state, (series: Vif['series'][number]) => {
        set(series, 'dataSource.hierarchies', tableHierarchies);
      });
      break;
    }

    case actions.RESET_TABLE_HIERARCHY: {
      const hierarchiesIndex = action.index;

      forEachSeries(state, (series: Vif['series'][number]) => {
        const hierarchyToReset = get(series, ['dataSource', 'hierarchies', hierarchiesIndex], {});
        set(series, ['dataSource', 'hierarchies', hierarchiesIndex], {
          id: hierarchyToReset.id,
          columnConfigurations: [],
          showGrandTotal: false,
          showSubTotal: false
        });

        // make the reset hierarchy active
        set(series, ['dataSource', 'activeHierarchyId'], hierarchyToReset.id);
      });
      break;
    }

    // When removing enable_flexible_table_hierarchies: we don't need to set this anymore
    case actions.SET_NON_STANDARD_AGGREGATION: {
      forEachSeries(state, (series: Vif['series'][number]) => {
        set(series, ['dataSource', 'nonStandardAggregation'], action.value);
      });
      break;
    }

    case actions.REMOVE_TABLE_HIERARCHY: {
      const hierarchiesIndex = action.index;

      forEachSeries(state, (series: Vif['series'][number]) => {
        // For columns that were in a removed hierarchy, set "hide" property to false
        const removedHierarchy: Hierarchy = get(series, `dataSource.hierarchies[${hierarchiesIndex}]`, null);
        if (removedHierarchy) {
          const columnsInRemovedHierarchy = map(removedHierarchy.columnConfigurations, 'columnName');
          each(get(series, 'dataSource.dimension.columns', []) as ViewColumn[], (column) => {
            if (column.fieldName && columnsInRemovedHierarchy.includes(column.fieldName)) {
              set(column, 'hide', false);
            }
          });
        }

        // filtering out the hierarchy array at the index specified
        // setting the hierarchy object to the newly filtered out array
        const filteredHierarchies = get(series, 'dataSource.hierarchies', []).filter(
          (h: Hierarchy, idx: number) => idx !== hierarchiesIndex
        );
        set(series, ['dataSource', 'hierarchies'], filteredHierarchies);

        // make the hierarchy that now lives at `action.index` as active, unless that's now an invalid index
        // (if the removed hierarchy was the last in the list, the index would be invalid); in that case, make
        // the last hierarchy active
        const nextActiveHierarchyIndex =
          hierarchiesIndex >= filteredHierarchies.length ? filteredHierarchies.length - 1 : hierarchiesIndex;
        const nextActiveHierarchyId = get(series, [
          'dataSource',
          'hierarchies',
          nextActiveHierarchyIndex,
          'id'
        ]);
        set(series, ['dataSource', 'activeHierarchyId'], nextActiveHierarchyId);
      });

      break;
    }

    case actions.DUPLICATE_TABLE_HIERARCHY: {
      const { id, index } = action;

      forEachSeries(state, (series: Vif['series'][number]) => {
        const nextHierarchies = cloneDeep(get(series, 'dataSource.hierarchies', []));
        const duplicate = cloneDeep(
          get(series, ['dataSource', 'hierarchies', index], {
            columnConfigurations: []
          })
        );

        duplicate.id = id;

        // place the new hierarchy directly after the source hierarchy
        nextHierarchies.splice(index + 1, 0, duplicate);
        set(series, ['dataSource', 'hierarchies'], nextHierarchies);

        // make the duplicate hierarchy active
        set(series, ['dataSource', 'activeHierarchyId'], duplicate.id);
      });
      break;
    }

    case actions.UPDATE_TABLE_HIERARCHY_NAME: {
      const { hierarchyIndex, name } = action;
      forEachSeries(state, (series: Vif['series'][number]) => {
        set(series, ['dataSource', 'hierarchies', hierarchyIndex, 'name'], name);

        // mark the updated hierarchy as active
        set(
          series,
          ['dataSource', 'activeHierarchyId'],
          get(series, ['dataSource', 'hierarchies', hierarchyIndex, 'id'])
        );
      });
      break;
    }

    case actions.SHOW_GRAND_TOTAL: {
      const { hierarchyIndex, isShowing } = action;
      forEachSeries(state, (series: Vif['series'][number]) => {
        set(series, ['dataSource', 'hierarchies', hierarchyIndex, 'showGrandTotal'], isShowing);

        // mark the updated hierarchy as active
        set(
          series,
          ['dataSource', 'activeHierarchyId'],
          get(series, ['dataSource', 'hierarchies', hierarchyIndex, 'id'])
        );
      });
      break;
    }

    case actions.SET_TABLE_ROW_STRIPING: {
      set(state, 'configuration.rowStripeStyle.base.text', action.tableRowStripeStyle?.base?.text);
      set(state, 'configuration.rowStripeStyle.base.fill', action.tableRowStripeStyle?.base?.fill);
      set(state, 'configuration.rowStripeStyle.alternate.text', action.tableRowStripeStyle?.alternate?.text);
      set(state, 'configuration.rowStripeStyle.alternate.fill', action.tableRowStripeStyle?.alternate?.fill);
      break;
    }

    case actions.UPDATE_TABLE_HIERARCHY: {
      const updatedColumnConfigs: HierarchyColumnConfig[] = action.updatedColumnConfigs;
      const hierarchiesIndex: number = action.index;

      // if any of the column's are configured to use a non-standard aggregation, we want to note that in the vif
      const nonStandardAggregation = updatedColumnConfigs.some(
        ({ aggregation }) =>
          aggregation && !HIERARCHY_AGGREGATION_TYPES.find(({ type }) => aggregation === type)
      );

      forEachSeries(state, (series: Vif['series'][number]) => {
        const existingHierarchy = get(series, ['dataSource', 'hierarchies', hierarchiesIndex], {});
        set(series, ['dataSource', 'hierarchies', hierarchiesIndex], {
          ...existingHierarchy,
          columnConfigurations: updatedColumnConfigs
        });

        // mark the updated hierarchy as active
        set(
          series,
          ['dataSource', 'activeHierarchyId'],
          get(series, ['dataSource', 'hierarchies', hierarchiesIndex, 'id'])
        );

        // When removing enable_flexible_table_hierarchies: We don't need to set this anymore
        set(series, ['dataSource', 'nonStandardAggregation'], nonStandardAggregation);
      });
      break;
    }

    case actions.SHOW_SUB_TOTAL: {
      const { hierarchyIndex, isShowing } = action;
      forEachSeries(state, (series: Vif['series'][number]) => {
        set(series, ['dataSource', 'hierarchies', hierarchyIndex, 'showSubTotal'], isShowing);

        // mark the updated hierarchy as active
        set(
          series,
          ['dataSource', 'activeHierarchyId'],
          get(series, ['dataSource', 'hierarchies', hierarchyIndex, 'id'])
        );
      });
      break;
    }

    case actions.SET_TABLE_INDENTED_LAYOUT: {
      set(state, 'configuration.isIndented', action.toggle);
      break;
    }

    case actions.SET_SHOW_PROCESS_DISCOVERY_ACTION: {
      const { showAction } = action;
      set(state, 'configuration.showProcessDiscoveryAction', showAction);
      break;
    }

    case actions.SET_SHOW_EXISTING_ACTION_COLUMN: {
      const { showExistingActionColumn } = action;
      set(state, 'configuration.showExistingActionColumn', showExistingActionColumn);
      break;
    }

    case actions.SET_SELECTED_EXISTING_COLUMN: {
      const { selectedExistingColumn } = action;
      set(state, 'configuration.selectedExistingColumn', selectedExistingColumn);
      break;
    }

    case actions.SET_SELECTED_ACTION_FOR_EXISTING_COLUMN: {
      const { selectedActionForExistingColumn } = action;
      set(state, 'configuration.selectedActionForExistingColumn', selectedActionForExistingColumn);
      break;
    }

    case actions.ADD_TABLE_COLUMN:
    case actions.SET_DESCRIPTION:
    case actions.REMOVE_TABLE_COLUMN:
    case actions.SELECT_ALL_COLUMNS:
    case actions.RESET_ALL_COLUMNS:
    case actions.RECEIVE_METADATA:
    case actions.REORDER_TABLE_COLUMNS:
    case actions.SET_DOMAIN:
    case actions.SET_DATASET_UID:
    case actions.SET_FILTERS:
    case actions.SET_TITLE:
    case actions.SET_UNIT_ONE:
    case actions.SET_UNIT_OTHER:
    case actions.SET_VIEW_SOURCE_DATA_LINK:
      return baseVifReducer(state, action);
    default:
      break;
  }

  return state;
}
