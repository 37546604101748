import React from 'react';
import { ForgeCheckbox } from '@tylertech/forge-react';

interface CustomCheckBoxProps {
  isChecked: boolean;
  label: string;
  onClick: () => void;
}

const CustomCheckBox = ({ isChecked, label, onClick }: CustomCheckBoxProps) => {
  return (
    <ForgeCheckbox>
      <input id={label} type="checkbox" aria-label={label} checked={isChecked} onClick={onClick} readOnly />
      <label htmlFor={label} slot="label">
        {label}
      </label>
    </ForgeCheckbox>
  );
};

export default CustomCheckBox;
