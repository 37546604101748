import React from 'react';

import { ForgeSelect } from '@tylertech/forge-react';

interface Option {
  label: string;
  value: string;
}

interface ProcessDiscoverySelectProps {
  options: Option[];
  placeHolder: string;
  value: string;
  label: string;
  onChange: (event: CustomEvent) => void;
}

const ProcessDiscoverySelect = ({
  options,
  label,
  placeHolder,
  onChange,
  value = ''
}: ProcessDiscoverySelectProps): JSX.Element => {
  const forgeSelectAttributes = {
    'data-testid': 'process-disconvery-action-column-selection',
    label: label,
    placeholder: placeHolder,
    onChange: onChange,
    floatLabelType: 'always',
    options,
    value
  };

  return (
    <div className="authoring-field" key="actionColumnSelector">
      <div className="action-column-selector-container">
        <ForgeSelect {...forgeSelectAttributes} />
      </div>
    </div>
  );
};

export default ProcessDiscoverySelect;
